<template>
  <router-view />
</template>

<script>
export default {
  name: "ContainerRouterView",
  created() {},
  setup() {},
  methods: {},
};
</script>

<style scoped>
</style>
